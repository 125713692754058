import React from 'react'
import { CreateUserToken } from '../../../tango-react-base/components'

const signUpToken = () => {
  return (
    <div className="home-cnt container-home">
      <CreateUserToken />
    </div>
  )
}
export default signUpToken
