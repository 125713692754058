const initialState: any = {
  cities: [],
  fetchingCities: 'NO_CITIES',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_CITIES_STARTED':
      return {
        ...state,
        fetchingCities: 'FETCHING_CITIES',
      }

    case 'GET_CITIES_SUCCESS':
      return {
        ...state,
        cities: action.payload.data,
        fetchingCities: 'FETCHED_CITIES',
      }
    case 'GET_CITIES_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchingCities: 'ERROR_FETCHING_CITIES',
      }
    default:
      return state
  }
}
