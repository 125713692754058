import React from 'react'
import { LoginForm } from '../../../tango-react-base/components'
import { useLastLocation } from 'react-router-last-location'

const Login = () => {
  const lastLocation = useLastLocation()
  return (
    <div className="home-cnt container-home">
      <LoginForm lastLocation={lastLocation}/>
    </div>
  )
}
export default Login
