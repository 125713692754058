import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import intl from 'react-intl-universal'
import { setTemplateChild } from '../../../tango-react-base/reduxActions'
import PromotionForms from '../../../components/promotions'
import { updatePromotion, getPromotion } from '../../../redux/actions'
import { toast } from 'react-toastify'

let moment = require('moment')
interface PromotionUpdateProps {
  onSetTemplateChild: (child: Object) => void
  onUpdatePromotion: (values: Object, id: number) => void
  onGetPromotion: (id: number) => void
  promotion: any
  fetchPromotion: string
  fetchPromotionUpdate: string
}

const PromotionUpdate = ({
  onSetTemplateChild,
  onUpdatePromotion,
  onGetPromotion,
  promotion,
  fetchPromotion,
  fetchPromotionUpdate,
}: PromotionUpdateProps) => {
  const history = useHistory()
  const { id }: any = useParams()
  const promotionDate = moment(promotion?.attributes?.starts_at)
  const today = moment(new Date(), 'DD-MM-YYYY').format()

  let data: Array<Object> = []
  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login')
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('PROMOTIONS')}</h1>
        </>,
      )
      onGetPromotion(id)
    }
  }, [onGetPromotion])

  useEffect(() => {
    if (fetchPromotionUpdate === 'FETCHED_PROMOTION_UPDATE') {
      toast(intl.get('SUCCESFULLY_SAVED_DATA'))
      history.push('/promotions')
    }
  }, [fetchPromotionUpdate])

  const onSubmitUpdate = (values: any) => {
    Object.keys(values).forEach((key) => {
      if (values[key] === '0' && key.includes('discount')) {
        values[key] = null
      }
    })
    data.push({
      name: values.name,
      description: values.description,
      administration_fee_new_discount: values.administration_fee_new_discount,
      administration_fee_registered_discount: values.administration_fee_registered_discount,
      collection_fee_new_discount_owner: values.collection_fee_new_discount_owner,
      collection_fee_new_discount_renter: values.collection_fee_new_discount_renter,
      new_discount_duration: values.new_discount_duration,
      registered_discount_duration: values.registered_discount_duration,
      starts_at: values.starts_at,
      ends_at: values.ends_at,
      kind: values.kind,
      collection_fee_brokerage_discount_owner: values.collection_fee_brokerage_discount_owner,
      collection_fee_brokerage_discount_renter: values.collection_fee_brokerage_discount_renter,
      address: values.kind === 'condominium' ? values.address : null,
    })

    if (promotionDate <= today) {
      toast(intl.get('PROMOTION_NO_UPDATE'))
    } else {
      onUpdatePromotion(data[0], id)
    }
  }

  return (
    <>
      <div className='container-fluid p-0 col-10 py-4'>
        <div className='container-home pd-1'>
          <div className='template-line' />
          <h2 className='new-subtitle'>{intl.get('EDIT_PROMOTION')}</h2>
        </div>
        {fetchPromotion === 'FETCHED_PROMOTION' && (
          <PromotionForms
            update={true}
            promotion={promotion}
            disabled={false}
            onSubmit={onSubmitUpdate}
          >
            <div className='row mt-4'>
              <div className='col-6 text-align-center plm-0'>
                <button
                  type='submit'
                  disabled={(promotionDate <= today)}
                  className='button-primary col-11'
                >
                  {intl.get('EDIT')}
                </button>
              </div>
              <div className='col-6 text-align-center plm-0'>
                <button
                  type='button'
                  className='button-tertiary col-11'
                  onClick={(e: any) => history.push('/promotions')}
                >
                  {intl.get('CANCEL')}
                </button>
              </div>
            </div>
          </PromotionForms>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    fetchPromotion: state.promotions.fetchPromotion,
    fetchPromotionUpdate: state.promotions.fetchPromotionUpdate,
    promotion: state.promotions.promotion,
  }
}
const mapDispatchToProps = (dispatch: any, getState: any) => ({
  onSetTemplateChild: (child: Object) => {
    return dispatch(setTemplateChild(child, '/'))
  },
  onUpdatePromotion: (values: Object, id: number) => {
    return dispatch(updatePromotion(values, id))
  },
  onGetPromotion: (id: number) => {
    return dispatch(getPromotion(id))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PromotionUpdate)
