import { UserAdminState } from '../../../tango'

const initialState: UserAdminState = {
  userAdminCreate: {},
  error: {},
  fetch: 'NO_FETCH',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'CREATE_USER_ADMIN_STARTED':
      return {
        ...state,
        fetch: 'FETCHING',
      }
    case 'CREATE_USER_ADMIN_SUCCESS':
      return {
        ...state,
        userAdminCreate: action.payload.user.data,
        fetch: 'FETCHED',
      }
    case 'CREATE_USER_ADMIN_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetch: 'FETCHED_ERROR',
      }
    default:
      return state
  }
}
