import React, { useEffect, useState } from 'react'
import { FormikProps, withFormik, Form, Field } from 'formik'
import intl from 'react-intl-universal'
import { FormControlLabel, FormControl } from '@material-ui/core'
import {
  DatePicker,
  Input,
  MapAutoComplete,
  Select,
} from '../../tango-react-base/components'
import { FormValidation } from './formValidation'
import { toast } from 'react-toastify'
import moment from 'moment'
import { PROMOTIONS_KIND } from '../../helpers/selectOptions'

/**
 * Here it is defined 'PromotionFormPropsTypes' this is the list of promotions created
 * @typedef PromotionFormPropsTypes
 * @type {(function|Array|boolean)}
 * @property {function} onSubmit - is an function.
 * @property {Array} children - is an Array.
 * @property {Array} promotion - is an Array.
 * @property {boolean} disabled - is a boolean.
 * @property {boolean} update - is a boolean.
 */
interface PromotionFormProps {
  onSubmit?: any
  children?: any
  disabled: boolean
  promotion?: any
  update: boolean
}
/**
 * PromotionForm is a functional component
 *@function
 *@param {PromotionFormPropsTypes}  onSubmit - Creates or update a promotion.
 *@param {PromotionFormPropsTypes}  children - content for buttons
 *@param {PromotionFormPropsTypes}  disabled - if the component is disabled
 *@param {PromotionFormPropsTypes}  promotion - returns an object with promotion data
 *@param {PromotionFormPropsTypes}  update - if the promotion is going to be created or updated
 * @returns {(ReactComponent)} Returns a react component with a functional component
 */

 type DataOptions = string | number | undefined
interface PromotionForm {
  name: string | undefined // promotion name
  description: string | undefined // promotion description
  address: string // promotion address, this is only for pormotion condominium type
  kind: string // promotion type
  collection_fee_new_discount_owner: DataOptions
  collection_fee_new_discount_renter: DataOptions // % collection fee discount for renter only listed properties
  administration_fee_new_discount: DataOptions // % administration fee discount
  new_discount_duration: DataOptions // listed properties discount duration
  administration_fee_registered_discount: DataOptions // % discount administration fee registered properties
  registered_discount_duration: DataOptions // duration promotion registered properties
  ends_at: Date // date when promotion ends
  starts_at: Date // date when promotion starts
  administration_fee_registered_property: boolean | undefined // administration fee registered properties
  administration_fee_new_property: boolean | undefined // administration fee listed properties
  collection_fee_new_property: boolean | undefined // collection fee listed properties
  collection_fee_new_brokerage_property: boolean | undefined //collection fee for brokerage plans
  collection_fee_brokerage_discount_owner: DataOptions //  brokerage fee discount fo brokerage plans of owner
  collection_fee_brokerage_discount_renter: DataOptions //  brokerage fee discount fo brokerage plans of renter
}

const InnerForm: React.FunctionComponent<
  PromotionFormProps & FormikProps<PromotionForm>
> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  children,
  promotion,
  update,
}) => {
    const today = new Date()
    const [collectionFeeNew, setCollectionFeeNew] = useState(false)
    const [administrationFeeNew, setAdministrationFeeNew] = useState(false)
    const [
      administrationFeeRegistered,
      setAdministrationFeeRegistered,
    ] = useState(false)
    const [brokerageFee, setBrokerageFee] = useState(false)
    const {
      administration_fee_new_discount,
      new_discount_duration,
      administration_fee_registered_discount,
      registered_discount_duration,
      collection_fee_new_discount_owner,
      collection_fee_new_discount_renter,
      collection_fee_brokerage_discount_owner,
      collection_fee_brokerage_discount_renter,
    } = promotion?.attributes || ''
    const aasm_state = values

    useEffect(() => {
      if (
        administration_fee_registered_discount &&
        registered_discount_duration
      ) {
        setAdministrationFeeRegistered(true)
      }
      if (administration_fee_new_discount && new_discount_duration) {
        setAdministrationFeeNew(true)
      }
      if (collection_fee_new_discount_owner || collection_fee_new_discount_renter) {
        setCollectionFeeNew(true)
      }
      if (collection_fee_brokerage_discount_owner || collection_fee_brokerage_discount_renter) {
        setBrokerageFee(true)
      }
    }, [collection_fee_brokerage_discount_owner,
      collection_fee_brokerage_discount_renter,
      collection_fee_new_discount_owner,
      collection_fee_new_discount_renter,
      administration_fee_registered_discount,
      registered_discount_duration,
      administration_fee_new_discount,
      new_discount_duration
    ])

    return (
      <Form className='container-home pd-1'>
        <div className='container-fluid p-0 col-12'>
          <Input
            id='name'
            label='NAME'
            type='text'
            value={values.name}
            messageAdditionalValidation={errors.name}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.name}
            disabled={false}
            className='my-4'
            classNameInput='input-text'
            classNameLabel='label-input-text'
            justifyCenter='align-items-center'
            required={true}
          />
          <Input
            id='description'
            label='DESCRIPTION'
            type='text'
            value={values.description}
            messageAdditionalValidation={errors.description}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.description}
            disabled={false}
            className='my-4'
            classNameInput='input-text'
            multiline={true}
            rows={4}
            classNameLabel='label-input-text'
            justifyCenter='align-items-center'
            required={true}
          />

          <Select
            options={PROMOTIONS_KIND}
            id='kind'
            onChange={handleChange}
            label={'TYPE'}
            value={values.kind}
            disabled={false}
            classNameLabel='label-input-text'
            classNameSelect='select-style'
            classNameInput='input-text'
            className='my-4'
          />

          {values.kind === 'condominium' && (
            <MapAutoComplete
              values={values.address}
              id={'address'}
              label={'ADDRESS'}
              touched={touched.address}
              type='text'
              required={values.kind === 'condominium'}
              disabled={false}
              error={errors.address}
              typeMap='no-map'
              allValues={values}
              stateValidAddress={true}
              stateUnit={aasm_state}
              classNameLabel='label-input-text mt-2'
              classNameInput='input-text'
              hiddenMap={true}
              className='my-4'
            />
          )}
        </div>

        <div className='col-md-12 p-0 d-flex mt-4'>
          <span className={`${'form-control-label'} mt-4`}>
            <b>{intl.get('ADMINISTRATION_FEE')}</b>
          </span>
        </div>
        <div className='pl-3'>
          <div className='row pl-2 mt-2'>
            <div className='div-2  mt-3'>
              <FormControl>
                <FormControlLabel
                  className={`${'form-control-label-long'} pl-3`}
                  control={
                    <label className='container'>
                      <Field
                        type='checkbox'
                        id='administration_fee_new_property'
                        
                        value={values.administration_fee_new_property}
                        checked={administrationFeeNew}
                        onChange={() =>
                          setAdministrationFeeNew(!administrationFeeNew)
                        }
                        values={
                          (administrationFeeNew
                            ? values.administration_fee_new_property
                            : !values.administration_fee_new_property) || ''
                        }
                      />

                      <span className='checkmark'></span>
                    </label>
                  }
                  label={intl.get('PLAN_FULL')}
                />
              </FormControl>
            </div>
            <div className='col-12'>
              <Input
                id='administration_fee_new_discount'
                label='DISCOUNT_PORCENTAGE'
                type='number_with_adornament'
                justifyCenter='align-items-center'
                textAdornament='%'
                placeholder='0'
                // if checkbox is checked or the attribute is stored in the databa base, show the value, else update the value to undefined
                value={
                  administrationFeeNew || administration_fee_new_discount
                    ? values.administration_fee_new_discount
                    : (values.administration_fee_new_discount = undefined)
                }
                messageAdditionalValidation={
                  errors.administration_fee_new_discount
                }
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.administration_fee_new_discount}
                disabled={!administrationFeeNew}
                classNameInput='input-number'
                classNameLabel='label-input-number'
              />
            </div>
            <div className='col-12'>
              <Input
                id='new_discount_duration'
                label='DISCOUNT_DURATION'
                type='number_with_adornament'
                justifyCenter='align-items-center'
                placeholder='0'
                textAdornament={intl.get('MONTHS')}
                // if checkbox is checked or the attribute is stored in the databa base, show the value, else update the value to undefined
                value={
                  administrationFeeNew || new_discount_duration
                    ? values.new_discount_duration
                    : (values.new_discount_duration = undefined)
                }
                messageAdditionalValidation={errors.new_discount_duration}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.new_discount_duration}
                disabled={!administrationFeeNew}
                classNameInput='input-number'
                classNameLabel='label-input-number'
              />
            </div>
          </div>
        </div>
        <div className='pl-3'>
          <div className='row pl-2 mt-4 flex-column d-flex'>
            <div className='div-2 mt-3'>
              <FormControl>
                <FormControlLabel
                  className={`${'form-control-label-long'} pl-3`}
                  control={
                    <label className='container'>
                      <Field
                        type='checkbox'
                        placeholder='0'
                        id='administration_fee_registered_property'
                        value={values.administration_fee_registered_property}
                        checked={administrationFeeRegistered}
                        onChange={() =>
                          setAdministrationFeeRegistered(
                            !administrationFeeRegistered,
                          )
                        }
                        values={
                          administrationFeeRegistered
                            ? values.administration_fee_registered_property
                            : !values.administration_fee_registered_property
                        }
                      />

                      <span className='checkmark'></span>
                    </label>
                  }
                  label={intl.get('ADMINISTERED')}
                />
              </FormControl>
            </div>
            <div className='col-12'>
              <Input
                id='administration_fee_registered_discount'
                label='DISCOUNT_PORCENTAGE'
                type='number_with_adornament'
                justifyCenter='align-items-center'
                textAdornament='%'
                placeholder='0'
                // if checkbox is checked or the attribute is stored in the databa base, show the value, else update the value to undefined
                value={
                  administrationFeeRegistered ||
                    administration_fee_registered_discount
                    ? values.administration_fee_registered_discount
                    : (values.administration_fee_registered_discount = undefined)
                }
                messageAdditionalValidation={
                  errors.administration_fee_registered_discount
                }
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.new_discount_duration}
                disabled={!administrationFeeRegistered}
                classNameInput='input-number'
                classNameLabel='label-input-number'
              />
            </div>
            <div className='col-12'>
              <Input
                id='registered_discount_duration'
                label='DISCOUNT_DURATION'
                type='number_with_adornament'
                placeholder='0'
                justifyCenter='align-items-center'
                textAdornament={intl.get('MONTHS')}
                // if checkbox is checked or the attribute is stored in the databa base, show the value, else update the value to undefined
                value={
                  administrationFeeRegistered || registered_discount_duration
                    ? values.registered_discount_duration
                    : (values.registered_discount_duration = undefined)
                }
                messageAdditionalValidation={errors.registered_discount_duration}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.registered_discount_duration}
                disabled={!administrationFeeRegistered}
                classNameInput='input-number '
                classNameLabel='label-input-number'
              />
            </div>
          </div>
        </div>

        <div className='col-md-12 p-0 d-flex mt-4 '>
          <span className={`${'form-control-label'} mt-4`}>
            <b>{intl.get('COLLECTION_FEE')}</b>
          </span>
        </div>

        <div className='pl-3'>
          <div className='row pl-2 mt-4'>
            <div className='div-2 mt-3'>
              <FormControl>
                <FormControlLabel
                  className={`${'form-control-label-long'} pl-3 form-control-static`}
                  control={
                    <label className='container'>
                      <Field
                        type='checkbox'
                        id='collection_fee_new_property'
                        name='collection_fee_new_property'
                        value={values.collection_fee_new_property}
                        onChange={() => setCollectionFeeNew(!collectionFeeNew)}
                        checked={collectionFeeNew}
                        values={
                          collectionFeeNew
                            ? values.collection_fee_new_property
                            : !values.collection_fee_new_property
                        }
                      />
                      <span className='checkmark'></span>
                    </label>
                  }
                  label={intl.get('PLAN_FULL')}
                />
              </FormControl>
            </div>
            <div className='col-12'>
              <Input
                id='collection_fee_new_discount_owner'
                label='DISCOUNT_PORCENTAGE_OWNER'
                justifyCenter='align-items-center'
                type='number_with_adornament'
                placeholder='0'
                textAdornament='%'
                // if checkbox is checked or the attribute is stored in the databa base, show the value, else update the value to undefined
                value={
                  collectionFeeNew || collection_fee_new_discount_owner
                    ? values.collection_fee_new_discount_owner
                    : (values.collection_fee_new_discount_owner = undefined)
                }
                messageAdditionalValidation={errors.collection_fee_new_discount_owner}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.collection_fee_new_discount_owner}
                disabled={!collectionFeeNew}
                classNameInput='input-number'
                classNameLabel='label-input-number'
              />
            </div>
            <div className='col-12'>
              <Input
                id='collection_fee_new_discount_renter'
                label='DISCOUNT_PORCENTAGE_RENTER'
                justifyCenter='align-items-center'
                type='number_with_adornament'
                textAdornament='%'
                placeholder='0'
                // if checkbox is checked or the attribute is stored in the databa base, show the value, else update the value to undefined
                value={
                  collectionFeeNew || collection_fee_new_discount_renter
                    ? values.collection_fee_new_discount_renter
                    : (values.collection_fee_new_discount_renter = undefined)
                }
                messageAdditionalValidation={errors.collection_fee_new_discount_renter}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.collection_fee_new_discount_renter}
                disabled={!collectionFeeNew}
                classNameInput='input-number'
                classNameLabel='label-input-number'
              />
            </div>
          </div>
          <div className='row pl-2 mt-4'>
            <div className='div-2 mt-3'>
              <FormControl>
                <FormControlLabel
                  className={`${'form-control-label-long'} pl-3 form-control-static`}
                  control={
                    <label className='container'>
                      <Field
                        type='checkbox'
                        id='collection_fee_new_brokerage_property'
                        name='collection_fee_new_brokerage_property'
                        value={values.collection_fee_new_brokerage_property}
                        onChange={() => {
                          setBrokerageFee(!brokerageFee)
                        }}
                        checked={brokerageFee}
                        values={
                          brokerageFee
                            ? values.collection_fee_new_brokerage_property
                            : !values.collection_fee_new_brokerage_property
                        }
                      />

                      <span className='checkmark'></span>
                    </label>
                  }
                  label={intl.get('BROKERAGE')}
                />
              </FormControl>
            </div>
            <div className='col-12'>
              <Input
                id='collection_fee_brokerage_discount_owner'
                label='DISCOUNT_PORCENTAGE_OWNER'
                type='number_with_adornament'
                justifyCenter='align-items-center'
                textAdornament='%'
                placeholder='0'
                // if checkbox is checked or the attribute is stored in the databa base, show the value, else update the value to undefined
                value={
                  brokerageFee || collection_fee_brokerage_discount_owner
                    ? values.collection_fee_brokerage_discount_owner
                    : (values.collection_fee_brokerage_discount_owner = undefined)
                }
                messageAdditionalValidation={
                  errors.collection_fee_brokerage_discount_owner
                }
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.collection_fee_brokerage_discount_owner}
                disabled={!brokerageFee}
                classNameInput='input-number'
                classNameLabel='label-input-number'
              />
            </div>
            <div className='col-12'>
              <Input
                id='collection_fee_brokerage_discount_renter'
                label='DISCOUNT_PORCENTAGE_RENTER'
                type='number_with_adornament'
                justifyCenter='align-items-center'
                textAdornament='%'
                placeholder='0'
                // if checkbox is checked or the attribute is stored in the databa base, show the value, else update the value to undefined
                value={
                  brokerageFee || collection_fee_brokerage_discount_renter
                    ? values.collection_fee_brokerage_discount_renter
                    : (values.collection_fee_brokerage_discount_renter = undefined)
                }
                messageAdditionalValidation={
                  errors.collection_fee_brokerage_discount_renter
                }
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.collection_fee_brokerage_discount_renter}
                disabled={!brokerageFee}
                classNameInput='input-number'
                classNameLabel='label-input-number'
              />
            </div>
          </div>
        </div>
        <div className='row mt-4'>
          <div className='date-picker'>
            <DatePicker
              variant
              openTo
              name='starts_at'
              disableToolbar={false}
              value={values.starts_at}
              label='START_DATE'
              disabled={false}
              className='col-12 p-0'
              classNameLabel='col-sm-4 col-xs-12 p-0 mt-2 text-color-secondary'
              classNameInput='col-sm-8 col-xs-12 p-0'
              minDate={today}
              minDateMessage='DATE_FUTURE'
            />
          </div>
          <div className='date-picker'>
            <DatePicker
              variant
              openTo
              name='ends_at'
              disableToolbar={false}
              value={values.ends_at}
              label='END_DATE'
              disabled={false}
              className='col-12 p-0 '
              classNameLabel='col-sm-5 col-xs-12 p-0 mt-2 pl-2 text-color-secondary'
              classNameInput='col-sm-7 col-xs-12 p-0 '
              minDate={today}
              minDateMessage='DATE_FUTURE'
            />
          </div>
        </div>

        {children}
      </Form>
    )
  }

const PromotionForms = withFormik<PromotionFormProps, PromotionForm>({
  mapPropsToValues: (props: any) => {
    let initialValues = {}
    if (props.update) {
      const endMoment = moment(props.promotion.attributes.ends_at, 'DD-MM-YYYY')
      const endDate = moment(endMoment).format()
      const startMoment = moment(
        props.promotion.attributes.starts_at,
        'DD-MM-YYYY',
      )
      const startDate = moment(startMoment).format()

      initialValues = {
        name: props.promotion.attributes.name,
        description: props.promotion.attributes.description,
        collection_fee_new_discount_owner:
          props.promotion.attributes.collection_fee_new_discount_owner,
        collection_fee_new_discount_renter:
          props.promotion.attributes.collection_fee_new_discount_renter,
        administration_fee_new_discount:
          props.promotion.attributes.administration_fee_new_discount,
        new_discount_duration: props.promotion.attributes.new_discount_duration,
        administration_fee_registered_discount:
          props.promotion.attributes.administration_fee_registered_discount,
        registered_discount_duration:
          props.promotion.attributes.registered_discount_duration,
        ends_at: endDate,
        starts_at: startDate,
        address: props.promotion.attributes.address,
        kind: props.promotion.attributes.kind,
        collection_fee_brokerage_discount_owner:
          props.promotion.attributes.collection_fee_brokerage_discount_owner,
        collection_fee_brokerage_discount_renter:
          props.promotion.attributes.collection_fee_brokerage_discount_renter,
      }
    }

    return {
      ...initialValues,
      ...props,
    }
  },
  validationSchema: FormValidation,
  handleSubmit(values: PromotionForm, { props, setErrors }) {
    if (
      (values.administration_fee_registered_property &&
        (!values.administration_fee_registered_discount ||
          !values.registered_discount_duration)) ||
      (values.administration_fee_new_property &&
        (!values.administration_fee_new_discount ||
          !values.new_discount_duration)) ||
      (values.collection_fee_new_property &&
        (!values.collection_fee_new_discount_owner || !values.collection_fee_new_discount_renter))
    ) {
      toast(intl.get('DATA_EMPTY'))
    } else {
      props.onSubmit(values)
    }
  },
})(InnerForm)

export default PromotionForms
