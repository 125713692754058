export const postOwnerPaymentStart = (ids: string[]) => {
  return {
    type: 'POST_OWNER_PAYMENT_START',
    payload: { ids },
  }
}

export const postOwnerPaymentSuccess = (result: any, ids: string[], countrymanagerName: string) => {
  return {
    type: 'POST_OWNER_PAYMENT_SUCCESS',
    payload: { result, ids, countrymanagerName },
  }
}
export const postReferencesPaymentStart = (ids: string[]) => {
  return {
    type: 'POST_REFERENCES_PAYMENT_START',
    payload: { ids },
  }
}

export const postReferencesPaymentSuccess = (result: any, ids: string[], countrymanagerName: string) => {
  return {
    type: 'POST_REFERENCES_PAYMENT_SUCCESS',
    payload: { result, ids, countrymanagerName },
  }
}
