import Login from './views/user/login'
import ForgetPasswordEmail from './views/user/forgetPasswordEmail'
import ForgetPasswordToken from './views/user/forgetPasswordToken'
import NewPassword from './views/user/newPassword'
import SignUp from './views/user/signUp'
import SignUpToken from './views/user/signUpToken'

/*Please seat the routes without any subroutes at the top. */
const routes = [
  { 
    path: '/login?email=email',
    exact: true,
    component: Login,
  },
  {
    path: '/login',
    exact: true,
    component: Login,
  },
  {
    path: '/sign_up/:email',
    exact: true,
    component: SignUpToken,
  },
  {
    path: '/sign_up',
    exact: true,
    component: SignUp,
  },
  {
    path: '/forget_password/:email/token/:token',
    exact: true,
    component: NewPassword,
  },
  {
    path: '/forget_password/:email',
    exact: true,
    component: ForgetPasswordToken,
  },
  {
    path: '/forget_password',
    exact: true,
    component: ForgetPasswordEmail,
  },
]

export default routes
