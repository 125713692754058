const initialState: any = {
  promotions: [],
  fetchPromotions: 'NO_PROMOTIONS',
  promotion: [],
  fetchPromotion: 'NO_PROMOTION',
  promotionCreate: [],
  fetchPromotionCreate: 'NO_PROMOTION_CREATE',
  promotionUpdate: [],
  fetchPromotionUpdate: 'NO_PROMOTION_UPDATE',
}
export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_PROMOTIONS_STARTED':
      return {
        ...state,
        promotions: [],
        fetchPromotions: 'FETCHING_PROMOTIONS',
        fetchPromotionUpdate: 'NO_PROMOTION_UPDATE',
        fetchPromotionCreate: 'NO_PROMOTION_CREATE',
      }
    case 'GET_PROMOTIONS_SUCCESS':
      return {
        ...state,
        promotions: action.payload.result.data,
        fetchPromotions: 'FETCHED_PROMOTIONS',
        fetchPromotionUpdate: 'NO_PROMOTION_UPDATE',
        fetchPromotionCreate: 'NO_PROMOTION_CREATE',
      }
    case 'GET_PROMOTIONS_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchPromotions: 'ERROR_FETCHING_PROMOTIONS',
      }
    case 'GET_PROMOTION_STARTED':
      return {
        ...state,
        promotion: [],
        fetchPromotion: 'FETCHING_PROMOTION',
        fetchPromotionUpdate: 'NO_PROMOTION_UPDATE',
        fetchPromotionCreate: 'NO_PROMOTION_CREATE',
      }
    case 'GET_PROMOTION_SUCCESS':
      return {
        ...state,
        promotion: action.payload.result.data,
        fetchPromotion: 'FETCHED_PROMOTION',
        fetchPromotionUpdate: 'NO_PROMOTION_UPDATE',
        fetchPromotionCreate: 'NO_PROMOTION_CREATE',
      }
    case 'GET_PROMOTION_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchPromotion: 'ERROR_FETCHING_PROMOTION',
      }
    case 'CREATE_PROMOTION_STARTED':
      return {
        ...state,
        promotionCreate: [],
        fetchPromotionCreate: 'FETCHING_PROMOTION_CREATE',
      }
    case 'CREATE_PROMOTION_SUCCESS':
      return {
        ...state,
        promotionCreate: action.payload,
        fetchPromotionCreate: 'FETCHED_PROMOTION_CREATE',
      }
    case 'CREATE_PROMOTION_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchPromotionCreate: 'ERROR_FETCHING_PROMOTION_CREATE',
      }
    case 'UPDATE_PROMOTION_STARTED':
      return {
        ...state,
        promotionUpdate: [],
        fetchPromotionUpdate: 'FETCHING_PROMOTION_UPDATE',
      }
    case 'UPDATE_PROMOTION_SUCCESS':
      return {
        ...state,
        promotionCreate: action.payload,
        fetchPromotionUpdate: 'FETCHED_PROMOTION_UPDATE',
      }
    case 'UPDATE_PROMOTION_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchPromotionUpdate: 'ERROR_FETCHING_PROMOTION_UPDATE',
      }
    case 'FINISH_PROMOTION_STARTED':
      return {
        ...state,
        fetchPromotionUpdate: 'FETCHING_PROMOTION_UPDATE',
      }
    case 'FINISH_PROMOTION_SUCCESS':
      return {
        ...state,
        promotionCreate: action.payload,
        fetchPromotionUpdate: 'FETCHED_PROMOTION_UPDATE',
      }
    case 'FINISH_PROMOTION_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchPromotionUpdate: 'ERROR_FETCHING_PROMOTION_UPDATE',
      }
    default:
      return state
  }
}