import * as Yup from 'yup'
import { setLocale } from 'yup'
import intl from 'react-intl-universal'

export const FormValidation = () => {
  setLocale({
    number: {
      min: intl.get('CHECK_VALUE_PERCENTAGE'),
      max: intl.get('CHECK_VALUE_PERCENTAGE'),
      integer: intl.get('CHECK_VALUE_NUMBER'),
    },
    mixed: {
      required: intl.get('REQUIRED'),
      notType: function notType(_ref) {
        if (_ref.type === 'number') {
          return intl.get('CHECK_VALUE_NUMBER')
        } else {
          return intl.get('WRONG_VALUE')
        }

      },
    },
  })

  return Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().required(),
    starts_at: Yup.date().required(),
    ends_at: Yup.date().required(),
    administration_fee_registered_discount: Yup.number()
      .nullable()
      .min(0)
      .max(100)
      .integer(),
    registered_discount_duration: Yup.number()
      .nullable()
      .min(1, `${intl.get('CHECK_VALUE_MONTH')}`)
      .max(12, `${intl.get('CHECK_VALUE_MONTH')}`)
      .integer(),
    administration_fee_new_discount: Yup.number()
      .nullable()
      .min(0)
      .max(100)
      .integer(),
    new_discount_duration: Yup.number()
      .nullable()
      .min(1, `${intl.get('CHECK_VALUE_MONTH')}`)
      .max(12, `${intl.get('CHECK_VALUE_MONTH')}`)
      .integer(),
    collection_fee_new_discount_owner: Yup.number()
      .nullable()
      .min(0)
      .max(100)
      .integer(),
    collection_fee_new_discount_renter: Yup.number()
      .nullable()
      .min(0)
      .max(100)
      .integer(),
    collection_fee_brokerage_discount_owner: Yup.number()
      .nullable()
      .min(0)
      .max(100)
      .integer(),
    collection_fee_brokerage_discount_renter: Yup.number()
      .nullable()
      .min(0)
      .max(100)
      .integer(),
  })
}
