import { combineReducers } from 'redux'
import userReducer from './userReducer'
import userAdminReducer from './userAdminReducer'
import navBarReducer from './navBarReducer'
import sessionReducer from './sessionReducer'
import ipLocationReducer from './ipLocationReducer'
import uploadFileReducer from './uploadFileReducer'
import notificationReducer from './notificationReducer'
import templateChildReducer from './templateChildReducer'
import countriesCodesReducer from './countriesCodesReducer'
import generateNewTokenReducer from './generateNewTokenReducer'
import promotionsReducer from './promotionsReducer'
import transactionsReducer from './transactionsReducer'
import communesReducer from './communesReducer'
import citiesReducer from './citiesReducer'

export default combineReducers({
  user: userReducer,
  navBar: navBarReducer,
  session: sessionReducer,
  userAdmin: userAdminReducer,
  ipLocation: ipLocationReducer,
  filesReducer: uploadFileReducer,
  notification: notificationReducer,
  templateChild: templateChildReducer,
  countriesCodes: countriesCodesReducer,
  generateNewToken: generateNewTokenReducer,
  promotions: promotionsReducer,
  transactions: transactionsReducer,
  communes: communesReducer,
  cities: citiesReducer,
})
