import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import intl from 'react-intl-universal'
import { setTemplateChild } from '../../../tango-react-base/reduxActions'
import PromotionForms from '../../../components/promotions'
import { createPromotion } from '../../../redux/actions'
import { toast } from 'react-toastify'
interface PromotionCreateProps {
  onSetTemplateChild: (child: Object) => void
  onAddPromotion: (values: Object) => void
  promotion: any
  fetchPromotionCreate: string
}

const PromotionCreate = ({
  onSetTemplateChild,
  onAddPromotion,
  promotion,
  fetchPromotionCreate,
}: PromotionCreateProps) => {
  const history = useHistory()

  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login')
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('PROMOTIONS')}</h1>
        </>,
      )
    }
  }, [])

  useEffect(() => {
    if (fetchPromotionCreate === 'FETCHED_PROMOTION_CREATE') {
      toast(intl.get('SUCCESFULLY_SAVED_DATA'))
      history.push('/promotions')
    }
  }, [fetchPromotionCreate])

  const onSubmit = (values: any) => {
    Object.keys(values).forEach((key) => {
      if (values[key] === '0' && key.includes('discount')) {
        values[key] = null
      }
    })
    let data: Array<Object> = []
    data.push({
      name: values.name,
      description: values.description,
      administration_fee_new_discount: values.administration_fee_new_discount,
      administration_fee_registered_discount:
        values.administration_fee_registered_discount,
      collection_fee_new_discount_owner: values.collection_fee_new_discount_owner,
      collection_fee_new_discount_renter: values.collection_fee_new_discount_renter,
      new_discount_duration: values.new_discount_duration,
      registered_discount_duration: values.registered_discount_duration,
      starts_at: values.starts_at,
      ends_at: values.ends_at,
      kind: values.kind,
      collection_fee_brokerage_discount_owner: values.collection_fee_brokerage_discount_owner,
      collection_fee_brokerage_discount_renter: values.collection_fee_brokerage_discount_renter,
      address: values.kind === 'condominium' ? values.address : null,
    })
    
    onAddPromotion(data[0])
  }
  return (
    <div className='container-fluid p-0 col-10 py-4'>
      <div className='container-home pd-1'>
        <div className='template-line' />
        <h2 className='new-subtitle'>{intl.get('CREATE_PROMOTION')}</h2>
      </div>

      <PromotionForms disabled={false} onSubmit={onSubmit} update={false}>
        <div className='row mt-4'>
          <div className='col-6 text-align-center plm-0'>
            <button type='submit' className='button-primary col-11'>
              {intl.get('CREATE')}
            </button>
          </div>
          <div className='col-6 text-align-center plm-0'>
            <button
              type='button'
              className='button-tertiary col-11'
              onClick={(e: any) => history.push('/promotions')}
            >
              {intl.get('CANCEL')}
            </button>
          </div>
        </div>
      </PromotionForms>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    fetchPromotionCreate: state.promotions.fetchPromotionCreate,
    promotion: state.promotions.promotionCreate,
  }
}
const mapDispatchToProps = (dispatch: any, getState: any) => ({
  onSetTemplateChild: (child: Object) => {
    return dispatch(setTemplateChild(child, '/'))
  },
  onAddPromotion: (values: Object) => {
    return dispatch(createPromotion(values))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PromotionCreate)
