// Owner Payment
export const getOwnerPaymentTransactionsStart = () => {
  return {
    type: 'GET_OWNER_TRANSACTIONS_STARTED',
  }
}

export const getOwnerPaymentTransactionsSuccess = (result: any) => {
  return {
    type: 'GET_OWNER_TRANSACTIONS_SUCCESS',
    payload: { result },
  }
}
export const getOwnerPaymentTransactionsError = (error: any) => {
  return {
    type: 'GET_OWNER_TRANSACTIONS_ERROR',
    payload: {
      error,
    },
  }
}
// References Payment
export const getReferencesPaymentTransactionsStart = () => {
  return {
    type: 'GET_REFERENCES_TRANSACTIONS_STARTED',
  }
}

export const getReferencesPaymentTransactionsSuccess = (result: any) => {
  return {
    type: 'GET_REFERENCES_TRANSACTIONS_SUCCESS',
    payload: { result },
  }
}
export const getReferencesPaymentTransactionsError = (error: any) => {
  return {
    type: 'GET_REFERENCES_TRANSACTIONS_ERROR',
    payload: {
      error,
    },
  }
}
