import { FilesReducerState } from '../../../tango'

const initialState: FilesReducerState = {
  filesUploaded: [],
  filesUploading: [],
  filesWithError: [],
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'UPLOAD_FILE_STARTED':
      return {
        ...state,
        filesUploading: state.filesUploading.concat(action.payload.fileIndex),
      }
    case 'UPLOAD_FILE_SUCCESS':
      return {
        ...state,
        file: action.payload.data,
        filesUploading: state.filesUploading.filter(
          (value: any, index: number, arr: any[]) => {
            return value !== action.payload.fileIndex
          },
        ),
        filesUploaded: state.filesUploaded.concat({
          file: action.payload.file.data,
          user_id: action.payload.file.data.attributes.user_id,
          fileIndex: action.payload.file.data.attributes.title,
        }),
      }
    case 'UPLOAD_FILE_ERROR':
      const { error } = action.payload
      return {
        ...state,
        error,
        uploadingFile: 'UPLOADING_FILE_ERROR',
        filesUploading: state.filesUploading.filter(
          (value: string) => value !== action.payload.fileIndex,
        ),
        filesWithError: state.filesWithError.concat({
          error,
          fileIndex: action.payload.fileIndex,
          errorMessage: 'Error uploading the file ',
        }),
      }
    case 'DELETE_FILE_STARTED':
      return {
        ...state,
      }
    case 'DELETE_FILE_SUCCESS':
      return {
        ...state,
        filesUploaded: state.filesUploaded.filter(
          (value: string) => value === action.payload.fileIndex,
        ),
      }
    case 'DELETE_FILE_ERROR':
      return {
        ...state,
        filesWithError: state.filesWithError.concat({
          error: action.payload,
          fileIndex: action.payload.fileIndex,
          errorMessage: 'Error deleting the file ',
        }),
      }

    default:
      return state
  }
}
