import {
  getPromotionsStart,
  getPromotionsSuccess,
  getPromotionsError,
} from './types'
import { getData } from '../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
const getPromotions = () => {
  return (dispatch: any) => {
    dispatch(getPromotionsStart())
    getData(`promotions`)
      .then((result) => {
        dispatch(getPromotionsSuccess(result.data))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(getPromotionsError(e))
      })
  }
}
export default getPromotions
