import intl from 'react-intl-universal'
import { formatDates } from '../../../tango-react-base/helpers/date'

const initialState: any = {
  ownerPayment: [],
  referencesPayment: [],
  fetchOwnerPaymentTransactions: 'NO_OWNER_TRANSACTIONS',
  fetchReferencesPaymentTransactions: 'NO_REFERENCES_TRANSACTIONS',
  methods: {
    ownerPayment: {
      post: 'NO_PAY',
      put: 'NO_UPDATE',
    },
    referencesPayment: {
      post: 'NO_PAY',
      put: 'NO_UPDATE',
    },
  },
}
export default (state = initialState, action: any) => {
  switch (action.type) {
    // GET OWNER PAYMENT
    case 'GET_OWNER_TRANSACTIONS_STARTED':
      return {
        ...state,
        fetchOwnerPaymentTransactions: 'FETCHING',
      }
    case 'GET_OWNER_TRANSACTIONS_SUCCESS':
      return {
        ...state,
        ownerPayment: action.payload.result.data,
        fetchOwnerPaymentTransactions: 'FETCHED',
      }
    case 'GET_OWNER_TRANSACTIONS_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchOwnerPaymentTransactions: 'ERROR',
      }
    // POST Owner Payment
    case 'POST_OWNER_PAYMENT_START':
      return {
        ...state,
        methods: {
          ...state.methods,
          ownerPayment: {
            ...state.methods.ownerPayment,
            post: 'SENDING',
          },
        },
        ownerPayment: [...state.ownerPayment].map((payment: any) =>
          action.payload.ids.indexOf(payment.id) !== -1
            ? {
              ...payment,
              attributes: { ...payment.attributes, payment_state: intl.get('LOADING') },
            }
            : payment,
        ),
      }
    case 'POST_OWNER_PAYMENT_SUCCESS':
      return {
        ...state,
        methods: {
          ...state.methods,
          ownerPayment: {
            ...state.methods.ownerPayment,
            post: 'SENT',
          },
        },
        ownerPayment: [...state.ownerPayment].map((payment: any) =>
          action.payload.ids.indexOf(payment.id) !== -1
            ? {
              ...payment,
              attributes: {
                ...payment.attributes,
                payment_state: 'Pagado',
                paid_at: formatDates({ date: new Date(), formatDate: 'short' }),
                payer_name: action.payload.countrymanagerName,
              },
            }
            : payment,
        ),
      }
    // PUT Owner Payment
    case 'PUT_OWNER_PAYMENT_START':
      return {
        ...state,
        methods: {
          ...state.methods,
          ownerPayment: {
            ...state.methods.ownerPayment,
            put: 'SENDING',
          },
        },
        ownerPayment: [...state.ownerPayment].map((payment: any) =>
          action.payload.id === payment.id
            ? {
              ...payment,
              attributes: {
                ...payment.attributes,
                payment_state: intl.get('LOADING'),
              },
            }
            : payment,
        ),
      }
    case 'PUT_OWNER_PAYMENT_SUCCESS':
      const { paid_at, payer_name, payment_state } = action.payload.result.data.attributes
      return {
        ...state,
        methods: {
          ...state.methods,
          ownerPayment: {
            ...state.methods.ownerPayment,
            put: 'SENT',
          },
        },
        ownerPayment: [...state.ownerPayment].map((payment: any) =>
          action.payload.id === payment.id
            ? {
              ...payment,
              attributes: {
                ...payment.attributes,
                payment_state,
                paid_at,
                payer_name,
              },
            }
            : payment,
        ),
      }
    // GET References payment
    case 'GET_REFERENCES_TRANSACTIONS_STARTED':
      return {
        ...state,
        fetchReferencesPaymentTransactions: 'FETCHING',
      }
    case 'GET_REFERENCES_TRANSACTIONS_SUCCESS':
      return {
        ...state,
        referencesPayment: action.payload.result.data,
        fetchReferencesPaymentTransactions: 'FETCHED',
      }
    case 'GET_REFERENCES_TRANSACTIONS_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchReferencesPaymentTransactions: 'ERROR',
      }
    // POST References Payment
    case 'POST_REFERENCES_PAYMENT_START':
      return {
        ...state,
        methods: {
          ...state.methods,
          referencesPayment: {
            ...state.methods.referencesPayment,
            post: 'SENDING',
          },
        },
        referencesPayment: [...state.referencesPayment].map((payment: any) =>
          action.payload.ids.indexOf(payment.id) !== -1
            ? {
              ...payment,
              attributes: {
                ...payment.attributes,
                payment_state: intl.get('LOADING')
              },
            }
            : payment,
        ),
      }
    case 'POST_REFERENCES_PAYMENT_SUCCESS':
      return {
        ...state,
        methods: {
          ...state.methods,
          referencesPayment: {
            ...state.methods.referencesPayment,
            post: 'SENT',
          },
        },
        referencesPayment: [...state.referencesPayment].map((payment: any) =>
          action.payload.ids.indexOf(payment.id) !== -1
            ? {
              ...payment,
              attributes: {
                ...payment.attributes,
                payment_state: 'Pagada',
                paid_at: formatDates({ date: new Date(), formatDate: 'short' }),
                payer_name: action.payload.countrymanagerName
              },
            }
            : payment,
        ),
      }
    // PUT References Payment
    case 'PUT_REFERENCES_PAYMENT_START':
      return {
        ...state,
        methods: {
          ...state.methods,
          referencesPayment: {
            ...state.methods.referencesPayment,
            put: 'SENDING',
          },
        },
        referencesPayment: [...state.referencesPayment].map((payment: any) =>
          action.payload.id === payment.id
            ? {
              ...payment,
              attributes: {
                ...payment.attributes,
                payment_state: intl.get('LOADING'),
              },
            }
            : payment,
        ),
      }
    case 'PUT_REFERENCES_PAYMENT_SUCCESS':
      return {
        ...state,
        methods: {
          ...state.methods,
          referencesPayment: {
            ...state.methods.referencesPayment,
            put: 'SENT',
          },
        },
        referencesPayment: [...state.referencesPayment].map((payment: any) =>
          action.payload.id === payment.id
            ? {
              ...payment,
              attributes: {
                ...payment.attributes,
                payment_state: 'Asignada',
                paid_at: '-',
                payer_name: intl.get('NOT_PAID')
              },
            }
            : payment,
        ),
      }
    default:
      return state
  }
}
