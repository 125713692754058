import { putData } from '../../../tango-react-base/helpers/apiConnections'
import {
  putOwnerPaymentStart,
  putOwnerPaymentSuccess,
} from './types'
const putOwnerTransactions = (id: string, countrymanagerName: string) => {
  return (dispatch: any) => {
    dispatch(putOwnerPaymentStart(id))
    putData(`owner_payments/${id}/unpay`)
      .then((result) => {
        return dispatch(putOwnerPaymentSuccess(result.data, id, countrymanagerName))
      })
  }
}
export default putOwnerTransactions
