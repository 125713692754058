import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ButtonsHeader from '../../tango-react-base/components/buttonsHeader/index'
import intl from 'react-intl-universal'
import { setTemplateChild } from '../../tango-react-base/reduxActions'
import { redirect_tabs } from '../buttonsOptions'
import getReferencesTransactions from '../../redux/actions/getTransactions/getReferencesPayment'
import getTransactionDocument from '../../redux/actions/getTransactionDocument/getTransactionDocument'
import { handleDownloadDirectFile } from '../../tango-react-base/helpers/getDirectFile/index'
import {
  ButtonLoading,
  CircularLoading,
  DialogModal,
  TangoData,
} from '../../tango-react-base/components'
import { titlesHeaderReferences } from './createTable'
import TransactionFilter from './transactionFilter'
import { datePaymentFilters } from '../../tango-react-base/helpers/dateLogicPaymentFilters'
import moment from 'moment'
import { buildFiltersTransaction } from '../../helpers/buildFiltersTransactions'
import {
  addReferencecsTransactions,
  putReferencesTransactions,
} from '../../redux/actions'
import { Typography } from '@material-ui/core'

type TransactionsProps = {
  onSetTemplateChild: (child: Object) => void
  onGetReferencesTransactions: (parameters?: any) => void
  OnAddReferencesTransactions: (ids: string[], name: string) => void
  UnPayReferencesTransactions: (id: string, name: string) => void
  allTransactions: Array<any>
  fetchTransactions: string
  countryManagerName: string
  methodsReferencePayment: {
    post: string
    put: string
  }
}
/**
 * Here it is defined the type of the property, this prop is similar to 'TransactionsProps' but 'TransactionsPropsTypes' is for the documentation
 * @typedef TransactionsPropsTypes
 * @type {(function|Array|string)}
 * @property {function} onSetTemplateChild - is a function.
 * @property {function} onGetOwnerTransactions - is a function.
 * @property {function} OnAddReferencesTransactions - is a function.
 * @property {function} UnPayReferencesTransactions - is a function.
 * @property {Array} allTransactions - is a Array.
 * @property {string} fetchTransactions - is a string.
 * @property {string} countryManagerName - is a string.
 */

let month = moment().format('MMMM').toUpperCase()

/**
 * Transactions is a functional component
 *@function Transactions
 *@param {TransactionsPropsTypes}  onSetTemplateChild - the template.
 *@param {TransactionsPropsTypes}  onGetOwnerTransactions - this does the dispatch to get the transactions
 *@param {TransactionsPropsTypes}  OnAddReferencesTransactions - this does the dispatch to pay transactions
 *@param {TransactionsPropsTypes}  UnPayReferencesTransactions - this does the dispatch to unpay transaction
 *@param {TransactionsPropsTypes}  allTransactions - returns all availabe transactions
 *@param {TransactionsPropsTypes}  fetchTransactions - returns the status of the transactions
 * @returns {(ReactComponent)} Returns a react component with a functional component
 */

const References = ({
  onSetTemplateChild,
  onGetReferencesTransactions,
  OnAddReferencesTransactions,
  UnPayReferencesTransactions,
  allTransactions,
  fetchTransactions,
  methodsReferencePayment,
  countryManagerName,
}: TransactionsProps) => {
  const history = useHistory()
  const [selected, setSelected] = useState([])
  const [months, setMonths] = useState([month])
  const [idsPaid, setIdsPaid] = useState<any>([])
  const [unPay, setUnPay] = useState({
    openModal: false,
    id: '',
  })
  const { post } = methodsReferencePayment

  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login')
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('TRANSACTIONS')}</h1>
          <p className='px-0 d-none d-md-block'>
            <br />
          </p>
          <ButtonsHeader
            imAdmin={true}
            buttonShowsArray={redirect_tabs}
            buttonSelected={'transactions'}
          />
        </>,
      )
    }
  }, [history, onSetTemplateChild])

  const dateFilters = datePaymentFilters()

  const [filters, setFilters] = useState({
    startDate: dateFilters.startDate,
    endDate: dateFilters.endDate,
    aasmState: 'all',
  })

  useEffect(() => {
    onGetReferencesTransactions(filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmitFilter = (values: any) => {
    const newFilters = {
      startDate: moment(values.starts_at),
      endDate: moment(values.ends_at),
      aasmState: values.transaccion_state,
    }
    setFilters(newFilters)
    onGetReferencesTransactions(newFilters)
    setMonths([
      moment(values.starts_at).format('MMMM').toUpperCase(),
      moment(values.ends_at).format('MMMM').toUpperCase(),
    ])
  }

  /**
   * This functions returns HeaderTransactions
   *@function HeaderTransactions
   * @returns {(ReactComponent)} Returns a react component with a functional component
   */

  const classEnabled = 'button-admin-tab width-100 mr-4 big-button w-100'
  const classDisabled =
    'button-admin-tab-disabled width-100 mr-4 big-button w-100'
  const buttons = [
    {
      titleButton: 'PLAN_FULL_TAB',
      action: () => {
        history.push(`/transactions?registerType=new`)
      },
      classes: classDisabled,
    },
    {
      titleButton: 'ADMINISTRATION',
      action: () => {
        history.push(`/transactions?registerType=registered`)
      },
      classes: classDisabled,
    },
    {
      titleButton: 'BROKERAGE',
      action: () => {
        history.push(`/transactions?registerType=brokerage`)
      },

      classes: classDisabled,
    },
    {
      titleButton: 'REFERENCES',
      action: () => {
        history.push('/transactions/references')
      },

      classes: classEnabled,
    },
  ]
  const HeaderTransactions = () => {
    return (
      <>
        <div className='row pl-1'>
          {buttons.map((value: any) => {
            return (
              <div className='tabs-div'>
                <button
                  className={value.classes}
                  onClick={() => {
                    value.action()
                  }}
                >
                  {intl.get(value.titleButton)}
                </button>
              </div>
            )
          })}
        </div>
      </>
    )
  }
  const idsSelected: any = allTransactions?.reduce(
    (accumulator, { id, attributes: { payment_state } = '' }) => {
      if (payment_state === 'Pagada') {
        accumulator.push(id)
      }
      return accumulator
    },
    [],
  )

  const fetched = fetchTransactions === 'FETCHED'
  const idsSelectedCallback = useCallback(() => {
    fetchTransactions === 'FETCHED' && setIdsPaid(idsSelected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetched, allTransactions])

  useEffect(() => {
    idsSelectedCallback()
  }, [idsSelectedCallback])

  /**
   * This functions submit the payments
   * @function onSubmitReferencesPayment
   */
  const onSubmitReferencesPayment = (idsSelected: string[]) => {
    OnAddReferencesTransactions(idsSelected, countryManagerName)
    setSelected([])
  }
  /**
   * This functions submit the unpay
   * @function onSubmitUnPay
   */
  const onSubmitUnPay = (id: string) => {
    UnPayReferencesTransactions(id, countryManagerName)
    setUnPay({ ...unPay, id: '', openModal: false })
  }
  /**
   * This functions returns ShowModal
   * @function ShowModal
   * @returns {(ReactComponent)} Returns a react component with a functional component
   */
  const ShowModal = () => {
    return (
      <DialogModal
        title={intl.get('CHANGE_STATUS')}
        open={unPay.openModal}
        handleClose={() => setUnPay({ ...unPay, id: '', openModal: false })}
      >
        <Typography gutterBottom>
          {intl.get('CHANGE_STATUS_TO_PENDING')}
          <br />
          <button
            type='button'
            className='button-primary mt-4 px-4'
            onClick={() => onSubmitUnPay(unPay.id)}
          >
            {intl.get('CONFIRM_BTN')}
          </button>
        </Typography>
      </DialogModal>
    )
  }
  /**
   *  This is a small component that contains a table with all transactions data
   *@function RenderTable
   * @returns {(React.Component )} Returns a react component with a functional component
   */
  const RenderTable = () => {
    const rowTableTransaction = allTransactions?.map(
      ({
        id,
        attributes: {
          referring_full_name,
          referenceable_type,
          commission_amount,
          referred_full_name,
          payment_state,
          overdue_date,
          paid_at,
          payer_name,
        } = '',
      }) => [
          id,
          payment_state,
          referenceable_type,
          referring_full_name,
          commission_amount,
          referred_full_name,
          moment(overdue_date, 'DD-MM-YYYY').format('DD-MM-YY'),
          paid_at ?? moment(paid_at).format('DD-MM-YY'),
          payer_name ? `${payer_name}` : '',
        ],
    )
    return (
      <TangoData
        listTitle={titlesHeaderReferences}
        listBody={rowTableTransaction}
        setSelected={setSelected}
        selected={selected}
        paginator={false}
        months={months}
        idsPaid={idsPaid}
        setUnPay={setUnPay}
      />
    )
  }
  const TRANSACTION_STATES = [
    { label: 'ALL', value: 'all' },
    { label: 'ASSIGNED', value: 'assigned' },
    { label: 'PAID', value: 'paid' },
  ]
  const payButtonDisabled = !selected.length
  return (
    <>
      <div className='p-2 mt-3'>
        <HeaderTransactions />
      </div>
      <div className='p-4 mt-2'>
        <TransactionFilter
          onSubmit={onSubmitFilter}
          states={TRANSACTION_STATES}
        >
          <button
            type='submit'
            disabled={fetchTransactions === 'FETCHING'}
            className='button-primary col-12 w-75'
          >
            {fetchTransactions === 'FETCHING' ? (
              <ButtonLoading />
            ) : (
              intl.get('FILTER')
            )}
          </button>
        </TransactionFilter>
      </div>
      <div className='p-2'>
        {fetchTransactions.includes('FETCHED') ? (
          <>
            <RenderTable />
            <div className={`row d-flex mb-3 justify-content-between`}>
              <button
                className={`button-secondary${payButtonDisabled ? '-disabled' : ''
                  } col-md-3 mt-5`}
                disabled={payButtonDisabled || post === 'SENDING'}
                onClick={() => onSubmitReferencesPayment(selected)}
              >
                {post === 'SENDING' ? <ButtonLoading /> : intl.get('PAY')}
              </button>
              <button
                className='button-fourth col-md-3 mt-5'
                onClick={() =>
                  handleDownloadDirectFile(
                    `reference_lines/excel${buildFiltersTransaction(filters)}`,
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    getTransactionDocument,
                  )
                }
              >
                {intl.get('DOWNLOAD_FILE')}
              </button>
            </div>
            <ShowModal />
          </>
        ) : (
          <CircularLoading />
        )}
      </div>
    </>
  )
}

/**
 *@function
 *@param {MapToProps}  dispatch - Dispatch an action to change the state.
 */
const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child))
    },
    onGetReferencesTransactions: (parameters?: any) => {
      dispatch(getReferencesTransactions(parameters))
    },
    OnAddReferencesTransactions: (ids: string[], name: string) => {
      dispatch(addReferencecsTransactions(ids, name))
    },
    UnPayReferencesTransactions: (id: string, name: string) => {
      dispatch(putReferencesTransactions(id, name))
    },
  }
}
/**
 *  * It's used for selecting the part of the data from the store that the connected component needs. It’s frequently referred to as just mapState for short
 * @typedef  MapToProps
 * @type {(function|object)}
 * @property {object} state - is a Object.
 * @property {function} dispatch - is a Function.
 */

/**
 *@function
 *@param {MapToProps}  state - here the redux data is obtained.
 */
const mapStateToProps = (state: any) => ({
  countryManagerName: state.session.user.attributes.names,
  allTransactions: state.transactions.referencesPayment,
  fetchTransactions: state.transactions.fetchReferencesPaymentTransactions,
  methodsReferencePayment: state.transactions.methods.referencesPayment,
})

export default connect(mapStateToProps, mapDispatchToProps)(References)
