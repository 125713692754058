import { createStore, applyMiddleware } from 'redux'
import reducers from './reducers'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

//Aplicar combine reducers. (Se puede ver un ejemplo en owner-master o renter-master)
const store: any = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk)),
)

export default store
