import { toast } from 'react-toastify'
import { postData } from '../../../tango-react-base/helpers/apiConnections'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
import {
  postReferencesPaymentStart,
  postReferencesPaymentSuccess,
} from './types'
const addReferencecsTransactions = (paymentIds: string[], countrymanagerName: string) => {
  const ids = {
    reference_lines: { ids: paymentIds }
  }

  return (dispatch: any) => {
    dispatch(postReferencesPaymentStart(paymentIds))
    postData('reference_lines/pay', ids)
      .then((result) => {
        return dispatch(postReferencesPaymentSuccess(result.data, paymentIds, countrymanagerName))
      })
      .catch((e) => {
        toast(handleError(e))
      })
  }
}
export default addReferencecsTransactions
