import React from 'react'
import { FormikProps, withFormik, Form } from 'formik'
import { DatePicker, Select } from '../../tango-react-base/components'
import {
  dateBrokerageFilters,
  datePaymentFilters,
} from '../../tango-react-base/helpers/dateLogicPaymentFilters'

/**
 * Here it is defined the type of the props for the interface "TransaccionFilterForm"
 * @typedef TransaccionFilterFormTypes
 * @type {(function|array)}
 * @property {function} onSubmit - is a function.
 * @property {function} children - is a children.
 * @property {array} states - is an array.
 *
 */
interface TransaccionFilterFormProps {
  onSubmit?: any
  states: object[]
  children?: any
  isBrokeragePlan?: boolean
  registerType?: string
}

interface TransaccionFilterForm {
  ends_at: Date
  starts_at: Date
  transaccion_state: string
}
/**
 * Here it is defined the type of the props, this prop is similar to 'ComponentProps' but 'ComponentPropsTypes' is for the documentation
 * @typedef TransaccionFilterFormTypes
 * @type {(date|strinng)}
 * @property {date} ends_at - is an Date.
 * @property {date} starts_at - is an Date.
 * @property {strinng} transaccion_state - is an string.
 */

/**
 * InnerForm(CreateNewTourOrVisitForm) is a functional component
 *@function
 *@param {TransaccionFilterFormTypes}  values - all values of the form (propertyUrl,email,startDate,blocks,fullAddress)
 *@param {TransaccionFilterFormTypes}  handleChange - detects changes in the input
 *@param {TransaccionFilterFormTypes}  handleBlur - returns an object
 *@param {TransaccionFilterFormTypes}  touched - returns a boolean indicating if it is "touched"
 *@param {TransaccionFilterFormTypes}  errors - returns an object with the errors
 *@param {TransaccionFilterFormTypes}  children - returns an children
 *@param {TransaccionFilterFormTypes}  isBrokeragePlan - returns if the property is brokerage register type
 * @returns {(React.FunctionComponent)} Returns a react component with a functional component
 */
const InnerForm: React.FunctionComponent<
  TransaccionFilterFormProps & FormikProps<TransaccionFilterForm>
> = ({
  values,
  handleChange,
  children,
  states,
  isBrokeragePlan,
  registerType,
}) => {
    return (
      <Form>
        <div className='row justify-content-center'>
          <div className='col-lg-3 col-sm-6'>
            <DatePicker
              variant
              name='starts_at'
              disableToolbar={false}
              value={values.starts_at}
              label='START_DATE'
              disabled={false}
              classNameInput='col-sm-6 p-0'
              classNameLabel='col-md-6 pl-0'
            />
          </div>
          <div className='col-lg-3 col-sm-6'>
            <DatePicker
              variant
              name='ends_at'
              disableToolbar={false}
              label='END_DATE'
              value={values.ends_at}
              disabled={false}
              classNameInput='col-sm-6 p-0'
              classNameLabel='col-md-6 pl-0'
            />
          </div>
          <div className='col-lg-3 col-sm-8'>
            <Select
              options={states}
              id='transaccion_state'
              onChange={handleChange}
              label={'STATUS'}
              value={values.transaccion_state}
              classNameInput='col-sm-6 p-0'
            />
          </div>
          <div className='col-lg-2 col-sm-4 p-0'>{children}</div>
        </div>
      </Form>
    )
  }

const TransaccionFilter = withFormik<
  TransaccionFilterFormProps,
  TransaccionFilterForm
>({
  mapPropsToValues: (props: any) => {
    const isBrokerage = props.registerType === 'brokerage'
    const filter =
      props.isBrokeragePlan || isBrokerage
        ? dateBrokerageFilters(7)
        : datePaymentFilters()

    const initialValues = {
      starts_at: filter.startDate,
      ends_at: filter.endDate,
      transaccion_state: 'all',
      registerType: props.registerType,
    }

    return {
      ...initialValues,
      ...props,
    }
  },

  handleSubmit(values: TransaccionFilterForm, { props }) {
    props.onSubmit(values)
  },
})(InnerForm)

export default TransaccionFilter
