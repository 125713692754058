import React from 'react'
import { NavBar, NavbarMenuOptions } from '../../tango-react-base/components'
import { connect } from 'react-redux'
import { useWindowSize } from '../../tango-react-base/helpers/hookWindowSize/index'
import {
  navBarMenuAction,
} from '../../tango-react-base/reduxActions'
import {
  admin854X400,
  admin1926X600,
  ownerA854X400,
  ownerA1926X600,
  white,
  tangoWhiteGreyLogo,
} from '../../tango-react-base/resources/index'

interface NavBarProps {
  navBarMenu: any
  childTemplate: any
}

const Navbar = ({ navBarMenu, childTemplate }: NavBarProps) => {
  let navMenu = <NavbarMenuOptions/>
  const size = useWindowSize()

  let templateAdminMobile = size.width <= 768 ? admin854X400 : admin1926X600
  let homeTemplateMobile = size.width <= 768 ? ownerA854X400 : ownerA1926X600

  let session: any

  const templateAndLogo = () => {
    session = sessionStorage.getItem('session')
    let templateAux =
      session === null ? homeTemplateMobile : templateAdminMobile
    const logo = tangoWhiteGreyLogo
    const color = white
    return [templateAux, logo, color]
  }
  const templateAndLogoAux = templateAndLogo()
  const template = templateAndLogoAux[0]
  const logo = templateAndLogoAux[1]
  const colorLine = templateAndLogoAux[2]

 const sizeTemplate = () => {
    if (size.width <= 768) { return '35rem' } 
    else if (session === null) { return '50%' } 
    else { return '100%'}
  }

  return (
    <div
      className='template-navbar h-phone-75'
      style={{
        backgroundImage: `url(${template}`,
        backgroundSize: `${sizeTemplate}`,
      }}
    >
      <div className='container-navbar'>
        <NavBar logo={logo} childrenMenuItem={navMenu}></NavBar>
        {Object.keys(childTemplate).length > 0 && (
          <div className='second-navbar'>
            <div
              className='template-line-title'
              style={{ backgroundColor: `${colorLine}` }}
            />
            {childTemplate}
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    fetchStatus: state.session.fetchStatus,
    anchorEl: state.navBar.anchorEl,
    childTemplate: state.templateChild.child,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    navBarMenu: (event: any, redirect?: any) => {
      return dispatch(navBarMenuAction(event, 'Same', redirect))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
