import { toast } from 'react-toastify'
import { getData } from '../../../tango-react-base/helpers/apiConnections'
import {
  getOwnerPaymentTransactionsStart,
  getOwnerPaymentTransactionsSuccess,
  getOwnerPaymentTransactionsError,
} from './types'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
import { buildFiltersTransaction } from '../../../helpers/buildFiltersTransactions'

const getOwnerTransactions = (params?: any) => {
  return (dispatch: any) => {
    dispatch(getOwnerPaymentTransactionsStart())
    getData(`owner_payments${params ? buildFiltersTransaction(params) : ''}`)
      .then((result) => {
        return dispatch(getOwnerPaymentTransactionsSuccess(result.data))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(getOwnerPaymentTransactionsError(e))
      })
  }
}
export default getOwnerTransactions
