import { TemplateChildState } from '../../../tango'

const initialState: TemplateChildState = {
  child: [],
  fetch: 'NO_FETCH',
}

export default (state: any = initialState, action: any) => {
  switch (action.type) {
    case 'TEMPLATE_CHILD_STARTED':
      return {
        ...state,
        fetch: 'FETCHING',
      }
    case 'TEMPLATE_CHILD_SUCCESS':
      return {
        ...state,
        child: action.payload.childen,
        fetch: 'FETCHED',
      }
    case 'TEMPLATE_CHILD_ERROR':
      return {
        ...state,
        error: action.payload.error,
      }
    default:
      return state
  }
}
