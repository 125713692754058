import { formatDates } from '../date';
import moment from 'moment';

export const datePaymentFilters = () => {
    const day15 = moment(Date.now()).set('date', 15);
    const currentDay = moment(Date.now());
    let startDate: any;
    let endDate: any;
    if (day15.date() < currentDay.date()) {
        startDate = moment(Date.now()).set('date', 15);
        endDate = moment(Date.now()).add(1, 'M').set('date', 14);
    } else {
        startDate = moment(Date.now()).subtract(1, 'M').set('date', 15);
        endDate = moment(Date.now()).set('date', 14);
    }

    return { startDate, endDate };
};

export const dateBrokerageFilters = (dayBack: number) => {
    const startDate = moment(Date.now()).subtract(dayBack, 'd');
    const endDate = moment(Date.now());

    return { startDate, endDate };
};

export const dateFilters = (dayBack: number) => {
    const currentDate = new Date();
    const startDate = currentDate.setDate(currentDate.getDate() - dayBack);
    const endDate = Date.now();

    return { startDate, endDate };
};
