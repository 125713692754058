import { toast } from 'react-toastify'
import { getData } from '../../../tango-react-base/helpers/apiConnections'
import {
  getReferencesPaymentTransactionsStart,
  getReferencesPaymentTransactionsSuccess,
  getReferencesPaymentTransactionsError,
} from './types'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
import { buildFiltersTransaction } from '../../../helpers/buildFiltersTransactions'

const getReferencesTransactions = (params?: any) => {
  return (dispatch: any) => {
    dispatch(getReferencesPaymentTransactionsStart())
    getData(`reference_lines${params ? buildFiltersTransaction(params) : ''}`)
      .then((result) => {
        return dispatch(getReferencesPaymentTransactionsSuccess(result.data))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(getReferencesPaymentTransactionsError(e))
      })
  }
}
export default getReferencesTransactions
