export const createPromotionStart = () => {
  return {
    type: 'CREATE_PROMOTION_STARTED',
  }
}

export const createPromotionSuccess = (promotion: any) => {
  return {
    type: 'CREATE_PROMOTION_SUCCESS',
    payload: { promotion },
  }
}

export const createPromotionError = (error: any) => {
  return {
    type: 'CREATE_PROMOTION_ERROR',
    payload: {
      error,
    },
  }
}
