import React, { useRef } from 'react';
import { uploadLogo, deleteLogo } from '../../resources/index';
import intl from 'react-intl-universal';
import { Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

interface DropZoneProps {
    onUploadImage: any;
    onDeleteImage: any;
    withFile: any;
    onOpenFile?: any;
    className?: string;
    accept?: string;
    optional?: boolean;
}

const DropZone = ({
    onUploadImage,
    onDeleteImage,
    withFile,
    onOpenFile,
    className,
    optional = true,
    accept = 'image/jpg,image/png,application/pdf',
}: DropZoneProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const openFileDialog = () => {
        if (fileInputRef.current) fileInputRef.current.click();
    };

    return (
        <div className={`dropzone-cnt mb-4 ${className}`}>
            {withFile === 'empty' ? (
                <div>
                    <img src={uploadLogo} alt='uploadLogo' className='upload-logo' onClick={openFileDialog} />
                    <input
                        name='file-uploader'
                        required={!optional}
                        ref={fileInputRef}
                        className='dropzone-file-input'
                        type='file'
                        multiple
                        accept={accept}
                        onChange={onUploadImage}
                    />
                </div>
            ) : (
                <div className='row m-0'>
                    {onOpenFile && (
                        <div className='col-6 column'>
                            <a href={onOpenFile} target='_blank'>
                                <i className='fa fa-eye text-white' />
                            </a>
                        </div>
                    )}
                    <div className='tooltip-delete-logo'>
                        <Tooltip title={intl.get('DELETE_DOCUMENT')} placement='top' arrow>
                            <img src={deleteLogo} alt='deleteLogo' className='upload-logo' onClick={onDeleteImage} />
                        </Tooltip>
                    </div>
                </div>
            )}
        </div>
    );
};
export default DropZone;
