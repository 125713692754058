import React from 'react'
import { TangoData } from '../../tango-react-base/components'
import { titlesHeaderLeases } from './createTable'

/**
 * Here it is defined the type of the property, this prop is similar to 'TransactionsComponentProps' but 'TransactionsPropsTypes' is for the documentation
 * @typedef TransactionsComponentPropsTypes
 * @type {(function|Array|string|boolean)}
 * @property {Array} dataTable - is a array.
 * @property {Array} months - is a array.
 * @property {Array} years - is a array.
 * @property {Array} idsPaid - is a array.
 * @property {function} onSubmitFilter - is a function.
 * @property {string} fetchTransactions - is a string.
 * @property {string} registerType - is a string.
 * @property {boolean} isBrokeragePlan - is a boolean.
 */

type TransactionsComponentProps = {
  dataTable: Array<any>
  months: Array<any>
  years: Array<any>
  setSelected: any
  selected: Array<any>
  idsPaid: Array<any>
  setUnPay: any
  onSubmitFilter: (values: any) => void
  fetchTransactions: string
  registerType: string
  isBrokeragePlan: boolean
}

/**
 * Transactions is a functional component
 *@function Transactions
 *@param {TransactionsComponentPropsTypes}  dataTable - the data to showin the table
 *@param {TransactionsComponentPropsTypes}  months - month(s) selected in the filter
 *@param {TransactionsComponentPropsTypes}  years - year(s) selected in the filter
 *@param {TransactionsComponentPropsTypes}  setSelected - Selected modifier function
 *@param {TransactionsComponentPropsTypes}  selected - stores selected values
 *@param {TransactionsComponentPropsTypes}  idsPaid - stores the values ​​that are already paid
 *@param {TransactionsComponentPropsTypes}  setUnPay - Unpay modifier function
 *@param {TransactionsComponentPropsTypes}  onSubmitFilter - returns the filters selected
 *@param {TransactionsComponentPropsTypes}  fetchTransactions - returns the gets trasnsaction state
 *@param {TransactionsComponentPropsTypes}  registerType - returns the register type
 *@param {TransactionsComponentPropsTypes}  isBrokeragePlan - returns if the filter selected is brokerage
 * @returns {(ReactComponent)} Returns a react component with a functional component
 */
const TransactionsComponent = ({
  dataTable,
  months,
  years,
  setSelected,
  selected,
  idsPaid,
  setUnPay,
  onSubmitFilter,
  fetchTransactions,
  registerType,
  isBrokeragePlan,
}: TransactionsComponentProps) => {
  const RenderTangoTable = () => {
    return (
      <TangoData
        listTitle={titlesHeaderLeases}
        listBody={dataTable}
        setSelected={setSelected}
        paginator={false}
        selected={selected}
        months={months}
        years={years}
        idsPaid={idsPaid}
        setUnPay={setUnPay}
      />
    )
  }

  return (
    <>
      <RenderTangoTable />
    </>
  )
}

export default TransactionsComponent
