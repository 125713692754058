export const titlesHeaderLeases = [
  { name: 'STATUS' },
  { name: 'PROPERTY' },
  { name: 'OWNER' },
  { name: 'PAYMENT_AMOUNT' },
  { name: 'OVERDUE_DATE' },
  { name: 'RENTER' },
  { name: 'DATE_PAY_SERVICE' },
  { name: 'PAID_BY', class: 'table-border-right' },
]
export const titlesHeaderReferences = [
  { name: 'STATUS' },
  { name: 'REFERENCEABLE_TYPE' },
  { name: 'REFERRING_USER' },
  { name: 'PAYMENT_AMOUNT' },
  { name: 'REFERRED_USER' },
  { name: 'OVERDUE_DATE' },
  { name: 'PAID_AT' },
  { name: 'PAID_BY', class: 'table-border-right' },
]

export const TRANSACTION_STATES = [
  { label: 'ALL', value: 'all' },
  { label: 'PENDING', value: 'created' },
  { label: 'PAID', value: 'paid' },
]

export const urlFile = 'owner_payments/excel'

export const urlExcelFile =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
