import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import intl from 'react-intl-universal'
import { setTemplateChild } from '../../tango-react-base/reduxActions'
import getOwnerTransactions from '../../redux/actions/getTransactions/getOwnerPayment'
import getTransactionDocument from '../../redux/actions/getTransactionDocument/getTransactionDocument'
import { handleDownloadDirectFile } from '../../tango-react-base/helpers/getDirectFile/index'
import {
  ButtonLoading,
  CircularLoading,
  DialogModal,
} from '../../tango-react-base/components'
import { urlExcelFile, urlFile, TRANSACTION_STATES  } from './createTable'
import { datePaymentFilters } from '../../tango-react-base/helpers/dateLogicPaymentFilters'
import { buildFiltersTransaction } from '../../helpers/buildFiltersTransactions'
import { Typography } from '@material-ui/core'
import { addOwnerTransactions, putOwnerTransactions } from '../../redux/actions'
import TransactionsComponent from './TransactionsComponent'
import { useHistory, useLocation } from 'react-router-dom'
import ButtonsHeader from '../../tango-react-base/components/buttonsHeader/index'
import { redirect_tabs } from '../buttonsOptions'
import { getParameterByName } from '../../tango-react-base/helpers/getParamsUrl'
import { formatDates } from '../../tango-react-base/helpers/date'
import TransactionFilter from './transactionFilter'

/**
 * Here it is defined the type of the property, this prop is similar to 'TransactionsProps' but 'TransactionsPropsTypes' is for the documentation
 * @typedef TransactionsPropsTypes
 * @type {(function|Array|string)}
 * @property {function} onSetTemplateChild - is a function.
 * @property {function} onGetOwnerTransactions - is a function.
 * @property {function} OnAddOwnerPaymentTransactions - is a function.
 * @property {function} UnPayOwnerPaymentTransactions - is a function.
 * @property {Array} allTransactions - is a Array.
 * @property {string} fetchTransactions - is a string.
 * @property {string} countryManagerName - is a string.
 * @property {string} methodsOwnerPayment - is a string.
 * @property {Array} months - is a array.
 * @property {Array} years - is a array.
 */
type TransactionsProps = {
  onSetTemplateChild: (child: Object) => void
  allTransactions: Array<any>
  methodsOwnerPayment: string
  months: Array<any>
  years: Array<any>
  OnAddOwnerPaymentTransactions: (ids: string[], name: string) => void
  countryManagerName: string
  fetchTransactions: string
  UnPayOwnerPaymentTransactions: (id: string, name: string) => void
  onGetOwnerTransactions: (parameters?: any) => void
}
/**
 * month variable gets the current month
 */
const month = new Date()
  .toLocaleString('en-US', { month: 'long' })
  .toUpperCase()
/**
 * year variable gets the current year
 */
const year = new Date().getFullYear()

/**
 * Transactions is a functional component
 *@function Transactions
 *@param {TransactionsPropsTypes}  onSetTemplateChild - the template.
 *@param {TransactionsPropsTypes}  onGetOwnerTransactions - this does the dispatch to get the transactions
 *@param {TransactionsPropsTypes}  OnAddOwnerPaymentTransactions - this does the dispatch to pay transactions
 *@param {TransactionsPropsTypes}  allTransactions - returns all availabe transactions
 *@param {TransactionsPropsTypes}  fetchTransactions - returns the status of the transactions
 *@param {TransactionsPropsTypes} UnPayOwnerPaymentTransactions - this does the dispatch to unpay transaction
 *@param {TransactionsPropsTypes}   methodsOwnerPayment - returns the status of the payment transactions
 *@param {TransactionsPropsTypes}   countryManagerName - returns the user's name
 * @returns {(ReactComponent)} Returns a react component with a functional component
 */
const Transactions = ({
  allTransactions,
  methodsOwnerPayment,
  OnAddOwnerPaymentTransactions,
  countryManagerName,
  fetchTransactions,
  UnPayOwnerPaymentTransactions,
  onGetOwnerTransactions,
  onSetTemplateChild,
}: TransactionsProps) => {
  const history = useHistory()
  let location = useLocation()

  /**
   * when a tab is clicked from references view it chould  get registerType to charge the corrsponding data
   */
  const paramsRegisterType = getParameterByName(
    'registerType',
    location?.search,
  )

  const [months, setMonths] = useState([month])
  const [years, setYears] = useState<any>([year])
  const [selected, setSelected] = useState<any>([])
  const [idsPaid, setIdsPaid] = useState<any>([])
  const registerTypes = ['new', 'registered', 'brokerage']
  const [registerType, setRegisterType] = useState(paramsRegisterType || 'new')
  const [isBrokeragePlan, setIsBrokeragePlan] = useState(false)
  const [unPay, setUnPay] = useState({
    openModal: false,
    id: '',
  })
  const payButtonDisabled = !selected.length
  const dateFilters = datePaymentFilters()
  const startDate = dateFilters.startDate
  const endDate = dateFilters.endDate
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState({
    startDate: startDate,
    endDate: endDate,
    aasmState: 'all',
    registerType,
  })
  const [chargeFilter, setChargeFilter] = useState(false)
  const registerTypeView = () => {
    if (registerType === 'new') {
      return 'PLAN_FULL_TAB'
    } else if (registerType === 'registered') {
      return 'ADMINISTRATION'
    } else {
      return 'BROKERAGE'
    }
  }




  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login')
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('TRANSACTIONS')}</h1>
          <p className='px-0 d-none d-md-block'>
            <br />
          </p>
          <ButtonsHeader
            imAdmin={true}
            buttonShowsArray={redirect_tabs}
            buttonSelected={'transactions'}
          />
        </>,
      )
      onGetOwnerTransactions(filters)
    }
  }, [history, onSetTemplateChild, onGetOwnerTransactions])

  /**
   * function getTransactions charges the table when a tab is clicked
   */
  const getTransactions = (registerType: string) => {
    const filters = {
      startDate: startDate,
      endDate: endDate,
      aasmState: 'all',
      registerType,
    }
    setFilters(filters)
    onGetOwnerTransactions(filters)
  }
  useEffect(() => {
    getTransactions(registerType)
  }, [registerType])

  useEffect(() => {
    onGetOwnerTransactions(filters)
  }, [registerType])

  /**
   * classEnabled and  classDisabled are the tabs classes
   */
  const classEnabled = 'button-admin-tab width-100 mr-4 big-button w-100'
  const classDisabled =
    'button-admin-tab-disabled width-100 mr-4 big-button w-100'
  /**
   * buttons are the tabs their content and actions
   */
  const buttons = [
    {
      titleButton: 'PLAN_FULL_TAB',
      action: () => {
        setRegisterType(registerTypes[0])
        setIsBrokeragePlan(false)
        setChargeFilter(true)
        history.push(`/transactions?registerType=new`)
        window.location.reload()
      },
      classes: registerType === registerTypes[0] ? classEnabled : classDisabled,
    },
    {
      titleButton: 'ADMINISTRATION',
      action: () => {
        setChargeFilter(true)
        history.push(`/transactions?registerType=registered`)
        setRegisterType(registerTypes[1])
        setIsBrokeragePlan(false)
        window.location.reload()
      },
      classes: registerType === registerTypes[1] ? classEnabled : classDisabled,
    },
    {
      titleButton: 'BROKERAGE',
      action: () => {
        setChargeFilter(true)
        history.push(`/transactions?registerType=brokerage`)
        setRegisterType(registerTypes[2])
        setIsBrokeragePlan(true)
        window.location.reload()
      },

      classes: registerType === registerTypes[2] ? classEnabled : classDisabled,
    },
    {
      titleButton: 'REFERENCES',
      action: () => {
        history.push('/transactions/references')
      },

      classes: classDisabled,
    },
  ]
  /**
   * function HeaderTransactions load the buttons tabs
   */
  const HeaderTransactions = () => {
    return (
      <>
        <div className='row pl-1'>
          {buttons.map((value: any) => {
            return (
              <div className='tabs-div ml-2'>
                <button
                  className={value.classes}
                  onClick={() => {
                    value.action()
                  }}
                >
                  {intl.get(value.titleButton)}
                </button>
              </div>
            )
          })}
        </div>
      </>
    )
  }
  /**
   * function onSubmitUnPay send for unpaid the payment
   */
  const onSubmitUnPay = (id: string) => {
    UnPayOwnerPaymentTransactions(id, countryManagerName)
    setUnPay({ ...unPay, id: '', openModal: false })
  }

  const idsSelected: any = allTransactions?.reduce(
    (accumulator, { id, attributes: { payment_state } = '' }) => {
      if (payment_state === 'Pagado') {
        accumulator.push(id)
      }
      return accumulator
    },
    [],
  )
  /**
   * function ShowModal show a modal to confir de unpayment
   */
  const ShowModal = () => {
    return (
      <DialogModal
        title={intl.get('CHANGE_STATUS')}
        open={unPay.openModal}
        handleClose={() => setUnPay({ ...unPay, id: '', openModal: false })}
        firstPosition
      >
        <Typography gutterBottom>
          {intl.get('CHANGE_STATUS_TO_PENDING')}
          <br />
          <button
            type='button'
            className='button-primary mt-4 px-4'
            onClick={() => onSubmitUnPay(unPay.id)}
          >
            {intl.get('CONFIRM_BTN')}
          </button>
        </Typography>
      </DialogModal>
    )
  }

  useEffect(() => {
    onGetOwnerTransactions(filters)
  }, [onGetOwnerTransactions, filters])

  const idsSelectedCallback = useCallback(() => {
    fetchTransactions === 'FETCHED' && setIdsPaid(idsSelected)
  }, [fetchTransactions, allTransactions])

  useEffect(() => {
    idsSelectedCallback()
  }, [idsSelectedCallback])

  /**
   * This functions submit the payments
   * @function onSubmitOwnerPayment
   */
  const onSubmitOwnerPayment = (idsSelected: string[]) => {
    OnAddOwnerPaymentTransactions(idsSelected, countryManagerName)
    setSelected([])
  }

  /**
   * This functions submit the unpay
   * @function onSubmitUnPay
   */

  /**
   * dataTable is the data to show in the table
   */
  const dataTable = allTransactions.map(
    ({
      id,
      attributes: {
        full_address,
        full_address_with_street_address_extra,
        owner_full_name,
        payment_amount,
        renter_full_name,
        payment_state,
        overdue_date,
        paid_at,
        payer_name,
      } = '',
    }) => [
      id,
      payment_state,
      full_address_with_street_address_extra,
      owner_full_name,
      payment_amount,
      overdue_date,
      renter_full_name,
      paid_at,
      payer_name,
    ],
  )

  /**
   * onSubmitFilter proccess a new fiter when filter button is clicked
   */
  const onSubmitFilter = (values: any) => {
    setChargeFilter(false)
    const startDate = new Date(values.starts_at)
    const endDate = new Date(values.ends_at)
    const newFilters = {
      startDate: startDate,
      endDate: endDate,
      aasmState: values.transaccion_state,
      registerType: registerType,
    }
    setFilters(newFilters)
    onGetOwnerTransactions(newFilters)

    setMonths([
      startDate.toLocaleString('en-US', { month: 'long' }).toUpperCase(),
      endDate.toLocaleString('en-US', { month: 'long' }).toUpperCase(),
    ])
    setYears([startDate.getFullYear(), endDate.getFullYear()])
  }

  const handleExcelFile = () => {
    setLoading(true)
    handleDownloadDirectFile(
      `${urlFile}${buildFiltersTransaction(filters)}`,
      urlExcelFile,
      getTransactionDocument,
      '',
      `${intl.get(`${registerTypeView}`)}-${formatDates({
        date: filters.startDate,
      })}-${formatDates({ date: filters.endDate })}`,
    ).finally(()=>{setLoading(false)})
  }

  const RenderTable = () => {
    return (
      <>
        <TransactionsComponent
          registerType={registerType}
          dataTable={dataTable}
          months={months}
          years={years}
          setSelected={setSelected}
          selected={selected}
          idsPaid={idsPaid}
          setUnPay={setUnPay}
          onSubmitFilter={onSubmitFilter}
          fetchTransactions={fetchTransactions}
          isBrokeragePlan={isBrokeragePlan}
        />
      </>
    )
  }
  const RenderTableActions = () => {
    return (
      <div className={`row d-flex mb-3 justify-content-between`}>
        <button
          type='submit'
          className={`button-secondary${
            payButtonDisabled ? '-disabled' : ''
          } col-md-3 mt-5`}
          disabled={payButtonDisabled || methodsOwnerPayment === 'SENDING'}
          onClick={() => onSubmitOwnerPayment(selected)}
        >
          {methodsOwnerPayment === 'SENDING' ? (
            <ButtonLoading />
          ) : (
            intl.get('PAY')
          )}
        </button>

        <button
          className='button-fourth col-md-3 mt-5'
          onClick={() => handleExcelFile()}
        >
          
          {loading ? <CircularLoading></CircularLoading> : intl.get('DOWNLOAD_FILE')}
        </button>
      </div>
    )
  }

  const RenderFilter = () => {
    return (
      <TransactionFilter
        isBrokeragePlan={isBrokeragePlan}
        onSubmit={onSubmitFilter}
        states={TRANSACTION_STATES}
        registerType={registerType}
      >
        <button
          type='submit'
          disabled={fetchTransactions === 'FETCHING'}
          className='button-primary col-12 w-75'
        >
          {fetchTransactions === 'FETCHING' ? (
            <ButtonLoading />
          ) : (
            intl.get('FILTER')
          )}
        </button>
      </TransactionFilter>
    )
  }
  return (
    <>
      <div className='p-2 mt-3'>
        <HeaderTransactions />
      </div>
      <div className='p-4 mt-2'>
        {chargeFilter ? (
          <RenderFilter />
        ) : (
          <TransactionFilter
            isBrokeragePlan={isBrokeragePlan}
            onSubmit={onSubmitFilter}
            states={TRANSACTION_STATES}
            registerType={registerType}
          >
            <button
              type='submit'
              disabled={fetchTransactions === 'FETCHING'}
              className='button-primary col-12 w-75'
            >
              {fetchTransactions === 'FETCHING' ? (
                <ButtonLoading />
              ) : (
                intl.get('FILTER')
              )}
            </button>
          </TransactionFilter>
        )}
      </div>
      <div className='p-2'>
        {fetchTransactions.includes('FETCHED') ? (
          <>
            <RenderTable />
            <RenderTableActions />
            <ShowModal />
          </>
        ) : (
          <CircularLoading />
        )}
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child))
    },
    onGetOwnerTransactions: (parameters?: any) => {
      dispatch(getOwnerTransactions(parameters))
    },
    OnAddOwnerPaymentTransactions: (ids: string[], name: string) => {
      dispatch(addOwnerTransactions(ids, name))
    },
    UnPayOwnerPaymentTransactions: (id: string, name: string) => {
      dispatch(putOwnerTransactions(id, name))
    },
  }
}
const mapStateToProps = (state: any) => ({
  countryManagerName: state.session?.user?.attributes?.names,
  allTransactions: state.transactions.ownerPayment,
  fetchTransactions: state.transactions.fetchOwnerPaymentTransactions,
  methodsOwnerPayment: state.transactions.methods.ownerPayment.post,
})

export default connect(mapStateToProps, mapDispatchToProps)(Transactions)
