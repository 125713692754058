import Home from './views/home'
import CreateUser from './views/create_user'
import BlankView from './views/blankView'
import TermAndCondition from './tango-react-base/components/termsAndCondition'
import AboutUs from './tango-react-base/components/aboutUs'
import Promotions from './views/promotions'
import Transactions from './views/transactions/index'
import References from './views/transactions/references'
import PromotionCreate from '../src/views/promotions/create'
import PromotionUpdate from '../src/views/promotions/update'

const routes: any[] = [
  {
    path: '/login',
    component: BlankView,
  },

  {
    path: '/sign_up/:email',
    component: BlankView,
  },
  {
    path: '/sign_up',
    component: BlankView,
  },
  {
    path: '/forget_password/:email/token/:token',
    component: BlankView,
  },
  {
    path: '/forget_password/:email',
    component: BlankView,
  },
  {
    path: '/forget_password',
    component: BlankView,
  },
  {
    path: '/create_user',
    component: CreateUser,
  },
  {
    path: '/terms-and-conditions',
    component: TermAndCondition,
  },
  {
    path: '/about-us',
    component: AboutUs,
  },
  {
    path: '/promotions',
    component: Promotions,
  },
  {
    path: '/transactions/references',
    component: References,
  },
  {
    path: '/transactions',
    component: Transactions,
  },
  {
    path: '/promotion/new',
    component: PromotionCreate,
  },
  {
    path: '/promotion/update/:id',
    component: PromotionUpdate,
  },
  {
    path: '/',
    component: Home,
  },
]

export default routes
