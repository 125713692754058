import React from 'react'
import { CreateUser } from '../../../tango-react-base/components'

const signUp = () => {
  return (
    <div className="home-cnt container-home">
      <CreateUser />
    </div>
  )
}
export default signUp
